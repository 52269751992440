import {flare} from '@flareapp/flare-client';
import {flareVue} from '@flareapp/flare-vue';
import {arrayIncludesAny} from "@/utilities";

if (import.meta.env.PROD) {
    flare.light();
}

flare.beforeSubmit = (report) => {

    if (filterUserAgent(report) === false) {
        return false;
    }

    return report;
};

function filterUserAgent(report) {
    const userAgent = report.context.request.useragent;
    // Don't send errors from bots
    return !arrayIncludesAny(userAgent, ['adsbot', 'googlebot']);
}

export default {
    flareVue,
};
