import DataObjectParser from 'dataobject-parser';

export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.keys(errors).includes(key)) {
            errors[value] = errors[key];
        }
    }

    console.table(errors);

    return errors;
}

export function getTimesBetween(start, end, step = 'day', stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export function pick(obj, fields) {
    return fields.reduce((acc, cur) => ((acc[cur] = obj[cur]), acc), {});
}

export function capitalFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ucFirst(string) {
    return capitalFirstLetter(string);
}

export function lcFirst(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function prefixKeys(prefix, obj) {
    if (obj == null) {
        return null;
    }

    return Object.keys(obj).reduce((a, c) => (a[`${prefix}${c}`] = obj[c], a), {});
}

export function toSelect(objectOrArray, nullLabel = 'Select an option', nullValue = '', emptyLabel = 'No options available') {
    if (Array.isArray(objectOrArray)) {
        if (objectOrArray.length === 0) {
            return [{label: emptyLabel, value: nullValue}];
        }

        return [{label: nullLabel, value: nullValue}, ...objectOrArray];
    }

    if (Object.keys(objectOrArray).length === 0) {
        return [{label: emptyLabel, value: nullValue}];
    }

    return {[nullValue]: nullLabel, ...objectOrArray};
}

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

export function arrayUniqueByKey(array, key = 'id') {
    array = array.filter(e => e);
    return array.filter((e, i) => array.findIndex(a => a[key] === e[key]) === i);
}

export function removeParam(key, sourceURL) {
    if (sourceURL === null) {
        return null;
    }

    var rtn = sourceURL.split('?')[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
        params_arr = queryString.split('&');
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split('=')[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + '?' + params_arr.join('&');
    }
    return rtn;
}

export function arrayIncludesAny(array, values) {
    array = array.map((value) => value.toLowerCase());
    values = values.map((value) => value.toLowerCase());

    return values.some((value) => array.includes(value));
}

export function addQueryParamsToUrl(url, params) {

    // if URL is relative, we'll need to add a fake base
    var fakeBase = !url.startsWith('http') ? 'http://fake-base.com' : undefined;
    var modifiedUrl = new URL(url || '', fakeBase);

    // add/update query params
    Object.keys(params).forEach(function (key) {
        if (modifiedUrl.searchParams.has(key)) {
            modifiedUrl.searchParams.set(key, params[key]);
        } else {
            modifiedUrl.searchParams.append(key, params[key]);
        }
    });

    // return as string (remove fake base if present)
    return modifiedUrl.toString().replace(fakeBase, '');
}
