import 'keen-slider/keen-slider.min.css';
import '@/bootstrap';

import {createInertiaApp} from '@inertiajs/vue3';
import {createSSRApp, h} from 'vue';

import VueNumber from 'vue-number-animation';
import VueApexCharts from "vue3-apexcharts";
// Pinia
import {createPinia} from 'pinia';
// Ziggy with locale
import {ZiggyVue} from '@/plugins/ziggy';
import {Ziggy} from '@/ziggy';

// Dayjs
import dayjs from '@/plugins/dayjs';

// Translations
import {createI18n} from 'vue-i18n';
import localeMessages from '@/vue-i18n-locales.generated';

import flareVue from '@/plugins/flare';

// Formkit
import formKitTheme from '@/formkitTheme';
import {createAutoAnimatePlugin, createAutoHeightTextareaPlugin} from '@formkit/addons';
import {nl} from '@formkit/i18n';
import {generateClasses} from '@formkit/themes';
import {defaultConfig as FKdc, plugin as FKp} from '@formkit/vue';

import Notifications from 'notiwind';

const pinia = createPinia();

const numberFormats = {
    nl: {
        currency: {
            style: "currency",
            currency: "EUR",
        },
        percent: {
            style: "percent",
            useGrouping: true,
        },
    },
    en: {
        currency: {
            style: "currency",
            currency: "EUR",
            notation: "standard",
        },
        decimal: {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: "percent",
            useGrouping: false,
        },
    },
};

createInertiaApp({
    progress: {color: '#4F46E5', delay: 500},
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue');
        return pages[`./Pages/${name}.vue`]();
    },
    setup({el, App, props, plugin}) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: 'en',
            messages: localeMessages,
            warnHtmlInMessage: !import.meta.env.PROD,
        });

        return createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(Notifications)
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(dayjs, {locale: currentLocale})
            .use(VueNumber)
            .use(VueApexCharts)
            .use(i18n)
            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({duration: 150}), createAutoHeightTextareaPlugin()],

                    locales: {nl},
                    locale: 'nl',
                    globalInjection: true,
                })
            )
            .mount(el);
    },
});
